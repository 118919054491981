import { Box, Text, Anchor } from 'grommet';
import { Next, Down, Up } from 'grommet-icons';
import { DateTime, Settings } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import Event from './event';
import parseEvents from './helpers';

const M365Events = ({ metadata, data }) => {
  const [currentTime, setCurrentTime] = useState(DateTime.now());
  const prevTime = useRef();

  const [showAllDay, setShowAllDay] = useState(0);

  useEffect(() => {
    // Update the current time value every second
    prevTime.current = currentTime;

    const tick = setInterval(() => {
      const now = DateTime.now();
      // only update the display if the minute changes
      if (now.get('minute') != prevTime.current.get('minute')) {
        setCurrentTime(now);
        prevTime.current = now;
      }
    }, 1000);
    return () => clearInterval(tick);
  }, []);

  if (!data.items) return;

  const timeZone = data.timezone || Settings.defaultZone;
  const eventData = parseEvents(data.items, currentTime.toUTC(), timeZone); // we have to parse the raw JSON from the notebook in the Card, because notebooks can't use Luxon
  const pastCount = eventData.pastCount || 0;
  const allDayCount = eventData.allDayCount || 0;
  const events = eventData.events || null;

  const showEvents = Array.isArray(events) && events.length - pastCount > 0 ? true : false;

  const allDayEvents = showEvents ? events.filter((event) => event.isAllDay) : null;
  const todayEvents = showEvents ? events.filter((event) => !event.isAllDay) : null;

  return (
    <Box>
      <Box align="baseline" justify="start" direction="row" pad={{ top: 'medium', bottom: 'small', horizontal: 'small' }}>
        <Text color="green" size="large">
          {currentTime.toLocaleString(DateTime.TIME_SIMPLE, { locale: 'en-US' })}
        </Text>
        <Text size="large" margin={{ left: 'small' }}>
          {currentTime.toLocaleString({ weekday: 'long', day: 'numeric', month: 'short' }, { locale: 'en-US' })}
        </Text>
        <Text margin={{ left: 'small' }} size="small" color="text-weak">
          {showEvents ? `(${events.length - pastCount} event${events.length - pastCount > 1 ? 's' : ''})` : '(0 events)'}
        </Text>
      </Box>
      <Box height="1px" background="border"></Box>
      {allDayCount > 0 && (
        <Box fill="horizontal" background="background-back" pad="small" direction="row" align="start" justify="between" wrap>
          {allDayEvents.map((event, i) => {
            if (i > 1) return null;
            return (
              <Text key={i} size="xsmall" margin={i > 0 ? { top: 'small' } : {}} style={i == 0 ? { flexBasis: '100%' } : {}}>
                {event.title}
              </Text>
            );
          })}
          {allDayCount > 2 && showAllDay == 0 && (
            <Anchor
              color="blue"
              size="xsmall"
              label={`View ${allDayCount - 2} more`}
              icon={<Down size="small" style={{ marginLeft: '-8px', marginTop: '-2px' }} />}
              reverse
              alignSelf="end"
              onClick={() => setShowAllDay(1)}
            />
          )}
          {allDayCount > 2 &&
            showAllDay == 1 &&
            allDayEvents.map((event, i) => {
              if (i <= 1) return null;
              return (
                <Text key={i} size="xsmall" margin={i > 0 ? { top: 'small' } : {}} style={i != allDayEvents.length - 1 ? { flexBasis: '100%' } : {}}>
                  {event.title}
                </Text>
              );
            })}
          {allDayCount > 2 && showAllDay == 1 && (
            <Anchor
              color="blue"
              size="xsmall"
              label="View less"
              icon={<Up size="small" style={{ marginLeft: '-8px', marginTop: '-2px' }} />}
              reverse
              alignSelf="end"
              onClick={() => setShowAllDay(0)}
            />
          )}
        </Box>
      )}
      {todayEvents ? (
        <Box pad={{ horizontal: 'small', top: 'medium' }} style={{ maxHeight: '500px' }}>
          {todayEvents.map((event, i) => {
            if (event.isPast == true) return null;
            return <Event key={i} data={event} />;
          })}
        </Box>
      ) : (
        <Box align="center" pad={{ horizontal: 'medium', bottom: 'small', top: 'medium' }}>
          <Text textAlign="center" size="large">
            You have no more events scheduled for&nbsp;today
          </Text>
          <Text textAlign="center" color="text-weak" margin={{ top: 'small' }}>
            You can go to Outlook to view your calendar, schedule events, and more.
          </Text>
          <Anchor
            label="Go to Outlook"
            href="https://outlook.office.com/"
            target="_blank"
            icon={<Next size="small" style={{ marginLeft: '-8px' }} />}
            reverse
            margin={{ top: 'medium' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default M365Events;
