import MD5 from 'md5.js';
import { DateTime, Settings, Duration } from 'luxon';

const parseEvents = (items, now, timeZone) => {
  let pastCount = 0;
  let allDayCount = 0;

  const avatarLink = (text, email) => {
    const baseUrl = 'https://app.adenin.com/';
    if (!text) return `${baseUrl}avatar`;

    if (text.length > 2) {
      const split = text.split(' ');
      text = '';

      for (let i = 0; i < split.length && i < 3; i++) {
        if (split[i] && split[i][0]) text += split[i][0];
      }
    }

    const platformAvatar = `${baseUrl}avatar/${encodeURIComponent(text)}`;

    if (!email || (typeof email !== 'string' && !(email instanceof String)) || !/^[ -~]+$/.test(text)) return platformAvatar;

    const gravatarBaseUrl = 'https://www.gravatar.com/avatar/';
    const md5 = new MD5();

    email = email.toLowerCase().trim();
    const hash = md5.update(email).digest('hex');
    return `${gravatarBaseUrl}${hash}?s=192&d=${encodeURIComponent(platformAvatar)}`;
  };

  const parseUrl = (text) => {
    text = text.replace(/\n|\r/g, ' ');
    const matches = text.match(/(https?)\:\/\/[A-Za-z0-9\.\-]+(\/[A-Za-z0-9\?\&\=;\+!'\(\)\*\-\._~%]*)*/gi);

    if (!matches || !matches.length) return null;

    for (let i = 0; i < matches.length; i++) {
      const match = matches[i];
      if (match.includes('webex') || match.includes('gotomeet') || match.includes('gotomeeting') || match.includes('zoom') || match.includes('skype') || match.includes('teams')) {
        return match;
      }
    }
    return matches[0];
  };

  const stripSpecialChars = (input) => {
    if (!input) return '';
    const index = input.search(/[^a-zA-z\s]/);
    if (index === -1) return input;
    return input.substring(0, index).trim();
  };

  let events = items?.map((item) => {
    const e = {
      id: item.id,
      title: item.subject,
      link: item.webLink,
      isCancelled: item.isCancelled,
      isRecurring: item.occurrenceId ? true : false,
    };

    e.description = item.bodyPreview ? item.bodyPreview.replace(/\r/g, '') : '';

    e.startDate = DateTime.fromISO(item.start.dateTime, { zone: item.start.timeZone }).toUTC().toLocal().toISO();
    e.endDate = DateTime.fromISO(item.end.dateTime, { zone: item.end.timeZone }).toUTC().toLocal().toISO();

    const when = DateTime.fromISO(e.startDate).diff(now, ['hours', 'minutes']);
    const duration = DateTime.fromISO(e.endDate).diff(DateTime.fromISO(e.startDate));

    if (duration.as('days') >= 1) {
      e.isAllDay = true;
      e.when = 'Now';
    } else {
      const parts = [];
      const hours = Math.floor(when.hours);
      const minutes = Math.floor(when.minutes) + 1; // seconds offset

      if (hours >= 1) {
        if (minutes < 30) {
          parts.push(hours);
          parts.push('.5 hours');
        } else {
          parts.push(hours + 1);
          parts.push(' hours');
        }
      } else if (when.minutes >= 1) {
        parts.push(minutes + ' minute' + (minutes > 1 ? 's' : ''));
      }

      if (parts.length > 0) {
        e.when = 'in ' + parts.join('');
      } else {
        e.when = 'Now';
      }
    }

    if (item.location && item.location.coordinates && item.location.coordinates.latitude) {
      e.location = {
        link: `https://www.google.com/maps/search/?api=1&query=${item.location.coordinates.latitude},${item.location.coordinates.longitude}`,
        title: item.location.displayName,
      };
    } else if (item.location.displayName && !item.onlineMeetingUrl) {
      const url = parseUrl(item.location.displayName);

      if (url !== null) {
        e.onlineMeetingUrl = url;
        e.location = null;
      }
    } else {
      e.location = null;
    }

    if (!e.onlineMeetingUrl) {
      const url = parseUrl(item.body.content);
      if (url !== null) e.onlineMeetingUrl = url;
    }

    if (e.onlineMeetingUrl) {
      // parse out which type of meeting
      const providers = [
        { name: 'Webex', match: 'webex' },
        { name: 'GoToMeeting', match: 'gotomeet' },
        { name: 'Zoom', match: 'zoom' },
        { name: 'Skype', match: 'skype' },
        { name: 'Microsoft Teams', match: 'teams' },
      ];
      const provider = providers.find((p) => e.onlineMeetingUrl.includes(p.match));
      e.meetingType = provider ? provider.name : null;
    }

    if (item.type == 'occurrence') {
      e.recurring = true;
    }

    const organizerName = stripSpecialChars(item.organizer.emailAddress.name);
    e.thumbnail = avatarLink(organizerName, item.organizer.emailAddress.address);
    e.imageIsAvatar = true;
    e.organizer = {
      avatar: e.thumbnail,
      email: item.organizer.emailAddress.address,
      name: organizerName,
    };

    e.attendees = [];

    if (item.attendees.length > 0) {
      for (let j = 0; j < item.attendees.length; j++) {
        const attendeeName = stripSpecialChars(item.attendees[j].emailAddress.name);
        const attendee = {
          email: item.attendees[j].emailAddress.address,
          name: attendeeName,
          avatar: avatarLink(attendeeName, item.attendees[j].emailAddress.address),
        };

        if (attendee.email === e.organizer.email) {
          attendee.response = 'organizer';
        } else if (item.attendees[j].status.response !== 'none') {
          attendee.response = item.attendees[j].status.response === 'accepted' ? 'accepted' : 'declined';
        }

        e.attendees.push(attendee);
      }
    } else {
      e.attendees = null;
    }

    switch (item.responseStatus.response) {
      case 'none':
        break;
      case 'organizer':
        e.response = {
          status: 'organizer',
        };
        break;
      default:
        e.response = {
          status: item.responseStatus.response === 'accepted' ? 'accepted' : 'declined',
          date: item.responseStatus.time,
        };
    }

    const startTime = DateTime.fromISO(item.start.dateTime, { zone: item.start.timeZone }).setZone(timeZone).toUTC().toLocal();
    const endTime = DateTime.fromISO(item.end.dateTime, { zone: item.end.timeZone }).setZone(timeZone).toUTC().toLocal();
    const halfAnHourAgo = now.minus({ minutes: 30 }).get('minute');

    if ((now.toISODate() == startTime.toISODate() || e.isAllDay) && endTime > halfAnHourAgo) {
      if (endTime < now) {
        pastCount++;
        e.isPast = true;
      }

      if (e.isAllDay) allDayCount++;
    }

    return e;
  });

  events = events.sort((a, b) => DateTime.fromISO(a.startDate).diff(DateTime.fromISO(b.startDate)).toMillis());

  const eventData = {
    events: events,
    pastCount: pastCount,
    allDayCount: allDayCount,
  };

  return eventData;
};

export default parseEvents;
